import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import * as classes from './NavigationMobileMenu.module.scss'
import classNames from 'classnames';

// Components
import { NavigationMobileMenuItem } from './NavigationMobileMenuItem';

const NavigationMobileMenu: React.FC = () => {
    // State
    const [open, setOpen] = useState(false);

    // Styles
    const menuStyles = classNames({
        [classes['menu']]: true,
        [classes['menuOpen']]: open
    });

    // Handlers
    const handleToggleMenu = () => {
        setOpen(prev => !prev);
    }

    return (
        <div className={classes['container']}>

            <button className={classes['button']} onClick={handleToggleMenu}>
                <FontAwesomeIcon icon={open ? faTimes : faBars} />
            </button>

            <ul className={menuStyles}>

                <NavigationMobileMenuItem 
                    label="Digital CV"
                    path="/"
                />

                <NavigationMobileMenuItem 
                    label="Portfolio"
                    path="/portfolio"
                />

                <NavigationMobileMenuItem 
                    label="Contact"
                    path="/contact"
                />

            </ul>

        </div>
    );
}

export { NavigationMobileMenu }