import React from 'react';
import classNames from 'classnames';
import * as classes from './SectionHeader.module.scss';

export interface SectionHeaderProps {
    title: string;
    description: string;
    className?: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
    title,
    description,
    className
}) => {
    const headerStyles = classNames({
        [classes['header']]: true,
        [className]: className
    });

    return (
        <header className={headerStyles}>
            <h3 className={classes['header__title']}>
                { title }<span>.</span>
            </h3>
            <p className={classes['header__description']}>
                { description }
            </p>
        </header>
    );
}

export { SectionHeader }