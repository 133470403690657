import React from 'react';
import { Link } from 'gatsby';
import * as classes from './Navigation.module.scss';

// Components
import { NavigationMenu } from './NavigationMenu';
import { NavigationMobileMenu } from './NavigationMobileMenu';

// Logo SVG
import Logo from '../../../../assets/svg/logo.inline.svg';

const Navigation: React.FC = () => {
    return (
        <div className={classes['container']}>
            <nav className={classes['navigation']}>

            <Link to="/" className={classes['logo']}>
                <div className={classes['logo__icon']}>
                    <Logo />
                </div>
                <div className={classes['logo__hover']} />
            </Link>

            <NavigationMobileMenu />

            <NavigationMenu />            

            </nav>
        </div>
    );
}

export { Navigation }