import React from 'react';
import { Link } from 'gatsby';
import * as classes from './NavigationMobileMenuItem.module.scss';

export interface NavigationMobileMenuItemProps {
    label: string;
    path: string;
}

const NavigationMobileMenuItem: React.FC<NavigationMobileMenuItemProps> = ({
    label,
    path
}) => {
    return (
        <li className={classes['menu__item']}>
            <Link to={path} className={classes['menu__anchor']} activeClassName={classes['menu__anchorActive']}>
                { label }<span>.</span>
            </Link>
        </li>
    );
}

export { NavigationMobileMenuItem }