import React from 'react';
import classNames from 'classnames';
import '../../assets/scss/global.scss';
import * as classes from './index.module.scss';

// Components
import { Navigation } from './components/navigation/Navigation';
import { Footer } from './components/footer/Footer';

export interface DefaultLayoutProps {
    className?: string;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
    className,
    children
}) => {
    const containerStyles = classNames({
        [classes.template]: true,
        [className]: className
    });

    return (
        <div className={containerStyles}>

            <Navigation />

            { children }

            <Footer />

        </div>
    );
}

export { DefaultLayout }