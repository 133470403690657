import React, { useRef, useState } from 'react';
import { Link } from 'gatsby';
import * as classes from './NavigationMenuItem.module.scss';

export interface NavigationMenuItemProps {
    label: string;
    path: string;
    animationDuration?: number;
}

const NavigationMenuItem: React.FC<NavigationMenuItemProps> = ({
    label,
    path,
    animationDuration = 150
}) => {
    // Calculate Interval Time
    const intervalTime = Math.floor(animationDuration / label.length);

    // Animation State
    const animationRef = useRef(null); 
    const [currentLetter, setCurrentLeter] = useState(0);

    // Handlers
    const handleMouseEnter = () => {
        animationRef.current = setInterval(() => {
            if (currentLetter === label.length) {
                clearInterval(animationRef.current);

                return;
            }

            setCurrentLeter((prev) => prev += 1);
        }, intervalTime);
    }

    const handleMouseLeave = () => {
        if (!animationRef.current) {
            return;
        }

        // Reset State
        setCurrentLeter(0);
        clearInterval(animationRef.current);
    }

    return (
        <li className={classes['menu__item']}>
            <Link to={path} className={classes['menu__anchor']} activeClassName={classes['menu__anchorActive']} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <span>{ label.slice(0, currentLetter) }</span>{ label.slice(currentLetter, label.length) }<span></span><span>.</span>
            </Link>
        </li>
    );
}

export { NavigationMenuItem }