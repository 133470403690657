import React from 'react';
import * as classes from './NavigationMenu.module.scss';

// Components
import { NavigationMenuItem } from './NavigationMenuItem';

const NavigationMenu: React.FC = () => {
    return (
        <ul className={classes['menu']}>

            <NavigationMenuItem 
                label="Digital CV"
                path="/"
            />

            <NavigationMenuItem 
                label="Portfolio"
                path="/portfolio"
            />

            <NavigationMenuItem 
                label="Contact"
                path="/contact"
            />

        </ul>
    )
}

export { NavigationMenu }