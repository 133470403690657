import React from 'react';
import * as classes from './Footer.module.scss';

const Footer: React.FC = () => {
    return (
        <div className={classes['container']}>
            <footer className={classes['footer']}>

            Designed & Developed by&nbsp;
            <strong>Krzysztof Szymański</strong>

            </footer>
        </div>
    );
}

export { Footer }